import { createStore } from './stores/store.js';
import { mapGetters } from 'vuex';

// App main
const site = async () => {
    // Async load the vue module
    const [ Vue, Lazysizes ] = await Promise.all([
        import(/* webpackChunkName: "vue" */ 'vue'),
        import(/* webpackChunkName: "lazysizes" */ 'lazysizes'),
    ])

    const store = await createStore(Vue.default);

    // Create our vue instance
    const vm = new Vue.default({
        el: "#page-container",
        store,
        computed: {
            ...mapGetters(['getCsrf', 'getGqlToken', 'getPlaceholders'])
        },
        data: () => ({
            hamburgerOpen: false,
            searchOpen: false,
        }),
        components: {
            'cards--news-slider': () => import(/* webpackChunkName: "cards--news-slider" */ '../vue/organisms/cards/cards--news-slider.vue'),
            'cards--recipe-slider': () => import(/* webpackChunkName: "cards--recipe-slider" */ '../vue/organisms/cards/cards--recipe-slider.vue'),
            'cards--events-slider': () => import(/* webpackChunkName: "cards--events-slider" */ '../vue/organisms/cards/cards--events-slider.vue'),
            'cards--lessons-slider': () => import(/* webpackChunkName: "cards--lessons-slider" */ '../vue/organisms/cards/cards--lessons-slider.vue'),
            'cards--load-more': () => import(/* webpackChunkName: "cards--load-more" */ '../vue/organisms/cards/cards--load-more.vue'),
            'cta--info': () => import(/* webpackChunkName: "cta--info" */ '../vue/molecules/cta/cta--info.vue'),
        },
        methods: {
            printPage() {
                window.print();
            },
            showSearch() {
                this.searchOpen = true;
            },
            hideSearch() {
                this.searchOpen = false;
            },
            async clearStorage() {
                if ( localStorage.getItem('growingchefs') ) {
                    let storage = JSON.parse(localStorage.growingchefs);
                    if ( !storage.version || storage.version !== '1.1.1' ) {
                        await localStorage.removeItem('growingchefs');
                    }
                }
            }
        },
        
        async mounted(){
            // Get the CSRF param to verify submissions before attempting any other queries
            if (!this.getCsrf) {
                await this.$store.dispatch("fetchCsrfToken");
            }

            // Wait for the GQL token before attempting GQL queries
            if (!this.getGqlToken) {
                await this.$store.dispatch("fetchGqlToken");
            }

            // if (!this.getPlaceholders) {
                await this.$store.dispatch("fetchPlaceholders");
            // }

            if (window.Formie) {
                window.Formie.initForms();
            }

        },
        
    });


};

// Execute async function
site().then( (root) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
