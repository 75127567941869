export const setGqlToken = (state, data) => {
    state.gqlToken = data;
}

export const setCsrf = (state, data) => {
    state.csrf = data;
}

export const setNews = (state, data) => {
    state.news.slider = data;
}

export const setLazyNews = (state, data) => {

    if ( state.news.lazy ) {
        state.news.lazy = [...state.news.lazy, ...data];
    } else {
        state.news.lazy = data;
    }
    
}

export const setRecipes = (state, data) => {
    state.recipes.slider = data;
}

export const setLazyRecipes = (state, data) => {

    if ( state.recipes.lazy ) {
        state.recipes.lazy = [...state.recipes.lazy, ...data];
    } else {
        state.recipes.lazy = data;
    }

}

export const setLazyVideos = (state, data) => {

    if ( state.videos.lazy ) {
        state.videos.lazy = [...state.videos.lazy, ...data];
    } else {
        state.videos.lazy = data;
    }

}

export const setLessons = (state, data) => {
    state.lessons.slider = data;
}

export const setLazyLessons = (state, data) => {

    if ( state.lessons.lazy ) {
        state.lessons.lazy = [...state.lessons.lazy, ...data];
    } else {
        state.lessons.lazy = data;
    }

}

export const setEvents = (state, data) => {
    state.events.slider = data;
}

export const setLazyEvents = (state, data) => {
    
    if ( state.events.lazy ) {
        state.events.lazy = [...state.events.lazy, ...data];
    } else {
        state.events.lazy = data;
    }

}

export const setPlaceholders = (state, data) => {
    state.placeholders = data;
}
