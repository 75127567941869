export const getCsrf = (state) => {
    return state.csrf;
}

export const getGqlToken = (state) => {
    return state.gqlToken;
}

export const getNews = (state) => {
    return state.news.slider;
}

export const getLazyNews = (state) => {
    return state.news.lazy;
}

export const getRecipes = (state) => {
    return state.recipes.slider;
}

export const getLazyRecipes = (state) => {
    return state.recipes.lazy;
}

export const getLazyVideos = (state) => {
    return state.videos.lazy;
}

export const getLessons = (state) => {
    return state.lessons.slider;
}

export const getLazyLessons = (state) => {
    return state.lessons.lazy;
}

export const getEvents = (state) => {
    return state.events.slider;
}

export const getLazyEvents = (state) => {
    return state.events.lazy;
}

export const getPlaceholders = (state) => {
    return state.placeholders;
}
