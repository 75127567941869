import axios from 'axios';
import { configureXhrApi, executeXhr } from '../utils/xhr.js';
import { configureGqlApi, executeGqlQuery } from '../utils/gql.js';
import { NEWS_QUERY, RECIPES_QUERY, PLACEHOLDERS_QUERY, LESSONS_QUERY, EVENTS_QUERY, VIDEOS_QUERY } from '../data/queries.js';

const CSRF_ENDPOINT = '/actions/site-module/csrf/get-csrf';
const TOKEN_ENDPOINT = '/actions/site-module/csrf/get-gql-token';
const GRAPHQL_ENDPOINT = '/api';

// Fetch & commit the CSRF token
export const fetchCsrfToken = async({commit, state}) => {
    const api = axios.create(configureXhrApi(CSRF_ENDPOINT));
    let variables = {
    };
    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('setCsrf', data);
    });
};

// Fetch & commit the GraphQL token
export const fetchGqlToken = async({commit, state}) => {
    const api = axios.create(configureXhrApi(TOKEN_ENDPOINT));
    let variables = {
        ...(state.csrf && { [state.csrf.name]: state.csrf.value }),
    };
    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('setGqlToken', data);
    });
};

export const fetchNews = async({commit, state}, options) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // Configure our API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {
        offset: options ? options.offset ?? null : null,
        limit: options ? options.limit ?? 10 : 10,
        category: options ? options.category ?? null : null,
    };

    // Execute the XHR
    await executeGqlQuery(api, NEWS_QUERY, variables, (data) => {
        if (data.entries) {
            options ? commit('setLazyNews', data.entries) : commit('setNews', data.entries);
        }
    })

}

export const fetchRecipes = async({commit, state}, options) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // Configure our API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {
        offset: options ? options.offset ?? null : null,
        limit: options ? options.limit ?? 10 : 10,
        category: options ? options.category ?? null : null,
    };

    // // Execute the XHR
    await executeGqlQuery(api, RECIPES_QUERY, variables, (data) => {
        if (data.entries) {
            options ? commit('setLazyRecipes', data.entries) : commit('setRecipes', data.entries);
        }
    })

}

export const fetchVideos = async({commit, state}, options) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // Configure our API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {
        offset: options ? options.offset ?? null : null,
        limit: options ? options.limit ?? 10 : 10,
        category: options ? options.category ?? null : null,
    };

    // // Execute the XHR
    await executeGqlQuery(api, VIDEOS_QUERY, variables, (data) => {
        if (data.entries && options) {
            commit('setLazyVideos', data.entries)
        }
    })

}

export const fetchLessons = async({commit, state}, options) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // Configure our API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {
        offset: options ? options.offset ?? null : null,
        limit: options ? options.limit ?? 10 : 10,
        category: options ? options.category ?? null : null,
    };

    // // Execute the XHR
    await executeGqlQuery(api, LESSONS_QUERY, variables, (data) => {
        if (data.entries) {
            options ? commit('setLazyLessons', data.entries) : commit('setLessons', data.entries);
        }
    })

}

export const fetchEvents = async({commit, state}, options) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // Configure our API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {
        offset: options ? options.offset ?? null : null,
        limit: options ? options.limit ?? 10 : 10,
        category: options ? options.category ?? null : null,
    };

    // // Execute the XHR
    await executeGqlQuery(api, EVENTS_QUERY, variables, (data) => {
        if (data.entries) {
            options ? commit('setLazyEvents', data.entries) : commit('setEvents', data.entries);
        }
    })

}

export const fetchPlaceholders = async({commit, state}) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {};

    // Execute the GQL Query
    await executeGqlQuery(api, PLACEHOLDERS_QUERY, variables, (data) => {
        if (data.globalSet) {
            commit('setPlaceholders', data.globalSet);
        }
    })
}