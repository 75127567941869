
// importing and setting up Font Awesome
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import {
    faEnvelope as farEnvelope,
    faFilePdf as farFilePdf,
    faFileExcel as farFileExcel,
    faFileWord as farFileWord,
    faFilePowerpoint as farFilePowerPoint,
    faFileArchive as farFileArchive,
} from '@fortawesome/free-regular-svg-icons';

import {
    faCloudDownloadAlt as fasCloudDownloadAlt,
    faExternalLinkAlt as fasExternalLinkAlt,
    faDownload as fasDownload,
    faPrint as fasPrint,
    faTimes as fasTimes,
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebookF as fabFacebookF,
    faInstagram as fabInstagram,
    faLinkedinIn as fabLinkedinIn,
    faTwitter as fabTwitter,
    faVimeoV as fabVimeoV,
    faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons';


// load font-awesome libraries
library.add(
    fabFacebookF,
    fabInstagram,
    fabLinkedinIn,
    fabTwitter,
    fabVimeoV,
    fabYoutube,
    farEnvelope,
    farFilePdf, 
    farFileExcel, 
    farFileWord, 
    farFilePowerPoint, 
    farFileArchive, 
    fasCloudDownloadAlt, 
    fasDownload, 
    fasExternalLinkAlt,
    fasPrint,
    fasTimes,
);

// convert i tags to SVG
dom.watch({
    autoReplaceSvgRoot: document,
    observeMutationsRoot: document.body
});