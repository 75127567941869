import * as actions from './actions.js';
import * as getters from './getters.js';
import * as mutations from './mutations.js';

// Main Store
export const createStore = async(Vue) => {
    const { default: Vuex } = await import(/* webpackChunkName: "vuex" */ 'vuex');
    const { default: createPersistedState } = await import(/* webpackChunkName: "vuex-persistedstate" */ 'vuex-persistedstate');
    Vue.use(Vuex);
    return new Vuex.Store({
        state: {
            csrf: null,
            gqlToken: null,
            news: {
                slider: null,
                lazy: null,
            },
            recipes: {
                slider: null,
                lazy: null,
            },
            events: {
                slider: null,
                lazy: null,
            },
            lessons: {
                slider: null,
                lazy: null,
            },
            videos: {
                lazy: null,
            },
            placeholders: null,
            version: '1.1.1',
        },
        getters,
        mutations,
        actions,
        modules: {},
        plugins: [
            createPersistedState({
                key: 'growingchefs',
            }),
        ]
    })
}
