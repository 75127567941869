export const EVENTS_QUERY = `
query EVENTS_QUERY($limit: Int, $offset: Int, $category: [QueryArgument]) {
    entries(section: "programsEvents", limit: $limit, offset: $offset, relatedTo: $category, orderBy: "dateTime desc"){
        id,
        title,
        url,
        typeHandle,
        ...on programsEvents_programsEvents_Entry {
            teaserImage {
                ...on teasers_Asset{
                    optimizeTeasers{
                        src,
                        srcset,
                        srcWebp,
                        srcsetWebp,
                        placeholderImage,
                    }
                }
            },
            description,
            dateTime@formatDateTime(format:"j F, Y, g:i A")
        }
    }
}
`
export const NEWS_QUERY = `
query NEWS_QUERY($limit: Int, $offset: Int, $category: [QueryArgument]) {
    entries(section: "news", limit: $limit, offset: $offset, relatedTo: $category, orderBy: "postDate desc"){
        id,
        title,
        url,
        typeHandle,
        ...on news_news_Entry {
            teaserImage {
                ...on teasers_Asset{
                    optimizeTeasers{
                        src,
                        srcset,
                        srcWebp,
                        srcsetWebp,
                        placeholderImage,
                    }
                }
            },
            description
        }
    }
}
`
export const LESSONS_QUERY = `
query LESSONS_QUERY($limit: Int, $offset: Int, $category: [QueryArgument]) {
    entries(section: "lessonPlans", limit: $limit, offset: $offset, relatedTo: $category, orderBy: "postDate desc"){
        id,
        title,
        url,
        typeHandle,
        ...on lessonPlans_lessonPlan_Entry {
            teaserImage {
                ...on teasers_Asset{
                    optimizeTeasers{
                        src,
                        srcset,
                        srcWebp,
                        srcsetWebp,
                        placeholderImage,
                    }
                }
            },
            description,
        }
    }
}
`
export const RECIPES_QUERY = `
query RECIPES_QUERY($limit: Int, $offset: Int, $category: [QueryArgument]) {
    entries(section: "recipes", limit: $limit, offset: $offset, relatedTo: $category, orderBy: "postDate desc"){
        id,
        title,
        url,
        typeHandle,
        ...on recipes_recipes_Entry {
            teaserImage {
                ...on teasers_Asset{
                    optimizeTeasers{
                        src,
                        srcset,
                        srcWebp,
                        srcsetWebp,
                        placeholderImage,
                    }
                }
            },
            yield,
            difficulty
        }
    }
}
`
export const VIDEOS_QUERY = `
query VIDEOS_QUERY($limit: Int, $offset: Int, $category: [QueryArgument]) {
    entries(section: "recipeVideos", limit: $limit, offset: $offset, relatedTo: $category, orderBy: "postDate desc"){
        id,
        title,
        url,
        typeHandle,
        ...on recipeVideos_videos_Entry {
            teaserImage {
                ...on teasers_Asset{
                    optimizeTeasers{
                        src,
                        srcset,
                        srcWebp,
                        srcsetWebp,
                        placeholderImage,
                    }
                }
            },
            metaDescription,
            videoCategories {
                title,
                ...on videoCategories_Category {
                    colour
                }
            }
        }
    }
}
`
export const PLACEHOLDERS_QUERY =
`
    query {
        globalSet(handle: "placeholders") {
            ...on placeholders_GlobalSet {
                teaserPlaceholder {
                    ...on teasers_Asset {
                        optimizeTeasers {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
                articlePlaceholder {
                    ...on article_Asset {
                        optimizeArticles {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
                profilePhotoPlaceholder {
                    ...on profiles_Asset {
                        optimizeProfileImages {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
                sliderPlaceholder {
                    ...on sliders_Asset {
                        optimizeSlider {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
            }
        }
    }
`
